<template>
     <Dialog v-model:visible="showEditDialogFlag" 
     :style="{width: '800px'}" :header="'Consultation'" :modal="true" @hide="this.$emit('close')" >  
        <!--  -->
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id" 
                    :filters="filters" class="p-datatable-sm"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                            <!-- <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                            </h5> -->
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                            </span>
                        </div>
                    </template>

                    <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                    <Column :exportable="false"  style="min-width:7rem">
                        <template #body="slotProps">
                            <P-Button icon="pi pi-eye" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="openEditDialog(slotProps.data)" />
                            <P-Button  icon="pi pi-print" class="p-button-outlined p-button-rounded" @click.prevent="scorePdf(slotProps.data.id)" />
                        </template>
                    </Column>
                    <!-- <Column field="raisonSociale" header="Raison sociale" :sortable="true" style="min-width:16rem"></Column> -->
                    <Column field="score" header="Score" :sortable="true" style="min-width:8rem">
                    <template #body="slotProps">
                        {{slotProps.data.score}}/10
                    </template>
                     </Column>
                    <Column field="dateScore" header="Date Score" :sortable="true" style="min-width:12rem">
                    <template #body="slotProps">
                        {{slotProps.data.dateScore?this.formatDate(slotProps.data.dateScore):""}}
                    </template>
                    </Column>
                    <!-- <Column field="dateCreation" header="Date Creation" :sortable="true" style="min-width:16rem"></Column> -->
                    <Column field="utilisateur" header="Analyste" :sortable="true" style="min-width:16rem"></Column>
                    <!-- <Column field="scoreFinancier" header="Score Financier" :sortable="true" style="min-width:16rem"></Column> 
                    <Column field="scoreQualitatif" header="Score Qualitatif" :sortable="true" style="min-width:16rem"></Column>
                    <Column field="poidsScoreFinancier" header="Poids Score Financier" :sortable="true" style="min-width:16rem"></Column>
                    <Column field="poidsScoreQualitatif" header="Poids Score Qualitatif" :sortable="true" style="min-width:16rem"></Column> -->
                    <!-- <Column field="dateValidite" header="Date Validite" :sortable="true" style="min-width:16rem"> </Column> -->
                    <!-- <Column field="natureRisque" header="Nature Risque" :sortable="true" style="min-width:16rem"> </Column>
                    <Column field="recommandation" header="Recommandation" :sortable="true" style="min-width:16rem"> </Column>  -->

                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                    
                </DataTable>
            </div>

        <!-- Delete Dialog -->
        

        <Dialog v-model:visible="showEditDialogFlagDetail" :style="{width: '450px'}" :header="'Consultation'" :modal="true" class="p-fluid">
            <div class="p-field">
                <label for="score">Score :</label>
                {{selectedGridItem.score}}/10
                <ErrorMessage name="score" class="error-feedback" />
                
            </div>

            <div class="p-field">
                <label for="dateScore">Date score :</label>
                {{this.formatDate(selectedGridItem.dateScore)}}
                <ErrorMessage name="dateScore" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="dateValidite" class="p-mb-3">Date validité :</label>
                {{this.formatDate(selectedGridItem.dateValidite)}}
                <ErrorMessage name="dateValidite" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="scoreFinancier" class="p-mb-3">Score financier :</label>
                {{selectedGridItem.scoreFinancier+"%"}}
                <ErrorMessage name="scoreFinancier" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="scoreQualitatif" class="p-mb-3">Score qualitatif :</label>
                {{selectedGridItem.scoreQualitatif+"%"}}
                <ErrorMessage name="scoreQualitatif" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="poidsScoreFinancier" class="p-mb-3">Poids score financier :</label>
                {{selectedGridItem.poidsScoreFinancier+"%"}}
                <ErrorMessage name="poidsScoreFinancier" class="error-feedback" />
            </div>
            
            <div class="p-field">
                <label for="poidsScoreQualitatif" class="p-mb-3">Poids score qualitatif :</label>
                {{selectedGridItem.poidsScoreQualitatif+"%"}}
                <ErrorMessage name="poidsScoreQualitatif" class="error-feedback" />
            </div>
            
            <div class="p-field">
                <label for="natureRisque" class="p-mb-3">Nature risque :</label>
                {{selectedGridItem.natureRisque}}
                <ErrorMessage name="natureRisque" class="error-feedback" />
            </div>
            
            <div class="p-field">
                <label for="recommandation" class="p-mb-3">Recommandation :</label>
                {{selectedGridItem.recommandation}}
                <ErrorMessage name="recommandation" class="error-feedback" />
            </div>
        
        </Dialog>
        
        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
        
    </Dialog>
        
        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    

    
    
</template>

<script>

import Header from "../Header.vue";
import CRUDService from "../../1-common-components/crud.service";
import {_} from 'vue-underscore';
import { Button } from '@progress/kendo-vue-buttons';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import { Chart } from '@progress/kendo-charts-vue-wrapper';
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';



export default {
  name: "ClientHistoriqueScoreDialog",
  components: {
    Header, 
        'k-button': Button,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        
        'kendo-datasource': KendoDataSource,
        'chart': Chart,
        'kendo-tabstrip': TabStrip,

        Dialog,
        ConfirmDialog,
        'V-Field':Field,
        ErrorMessage,
        
  },
  props:['societeId','showHistoriqueScoreDialogFlag'],
    emits:['close','scorePdfDialog'],
  data() {
        return {
            gridData: [],
            showEditDialogFlagDetail:false,
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            selectedGridInitialState: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
        } // end result
    }, //end data()
   
    mounted() {
        this.showEditDialogFlag = this.showHistoriqueScoreDialogFlag
        this.loadGridData();
        
                
    },
   // -------- grid init
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");

    },
  //----------Methods---------------------
  methods:{
    loadGridData: function(e)
        {
            var ref = this;
            this.log("------transportRead");
            this.log(e);
            CRUDService.get(this.societeId, "societywithscore").then(
                (response) => {
                    this.log(response.data);
                    this.gridData = response.data;
                
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
     openEditDialog(selectedItem){
            this.log("openEditDialog");
            this.log(selectedItem);
            
            this.showEditDialogFlagDetail = true;
            this.selectedGridItem = selectedItem;
            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
            
            
        },
    closeEditDialog(){
            this.log("closeEditDialog");
            this.showEditDialogFlag = false;
        }, 
    scorePdf : function(id)
        {
            ;
            this.$emit('scorePdfDialog',id)
        },          
    
  }
};
</script>


<!-- ########################## <Style> ########################## -->
<style >


</style>