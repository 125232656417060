<!-- ########################## <Template> ########################## -->
<template>

        <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Indicateur</th>
                        <th scope="col">Choix</th>
                        <th scope="col">Note</th>
                    </tr>
                </thead>
                <tr v-for="indicateur in listeIndicateurs" :key="indicateur.id" class="IQ2Element">
                    <ClientScoringIQ2Element
                            ref="ClientScoringIQ2Element{{indicateur.id}}"
                            :indicateurId="indicateur.id"
                            :libelleIndicateur="indicateur.libelleIndicateur"
                            :clientId="indicateur.clientId"
                            :echelleIndicateur="indicateur.echelleIndicateur"
                    >
                    </ClientScoringIQ2Element>
                </tr>
        </table>

</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientScoringIQ2Element from "./ClientScoringIQ2Element.vue";
import {_} from 'vue-underscore';
import { SlickList, SlickItem } from 'vue-slicksort';
import { setTransitionHooks } from '@vue/runtime-core';

export default {
    name: "ClientScoringIQ",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
       ClientScoringIQ2Element
    },
    //---------------------------- props ------------------------------
    props:   ['clientId'],
    //------------------------------ Data -----------------------------
    data() {
        return {
            errorMessage : ""
        }

    }, //end data()
    computed: {
        listeIndicateurs()
        {
            return this.$store.state.clientScoringStoreModule.IQsList;
        },
        
        
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientScoringIQ: clientId:" + this.clientId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("----------- mounted()  => ClientScoringIQ: clientId:" + this.clientId);
        this.fnLoadIndicateurs();
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientScoringIQ: clientId:" + this.clientId);
    },

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnLoadIndicateurs()
        {
            // we need to change the webapi endpoint for ClientUser / AdminUser for better security
            var endpoint = this.isAdminApplication?'indicateursqualitatifsadmincustom':'indicateursqualitatifsclientcustom';
            
            CRUDService.get(this.clientId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateurs reponse");
                this.log(response.data);
                // this.listeIndicateurs = response.data;
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    element.note = 0;
                    this.$store.commit('clientScoringStoreModule/updateIQsList', element);
                }
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.long-list {
  max-height: 12000px;
  overflow: auto;
}
    
</style>