import kendo from '@progress/kendo-ui'

class CommonService {

  // ############################## CRUD SERVICE ###############################
  getResultatFormule(formule, bilanJson,thisContext)
    {
        var resultString = "";
        var resultFloat = 0;
        thisContext.formuleStatus = "formuleValide";
        try{
            var template = kendo.template("#= "+formule+" #");
        
            var resultString = template(bilanJson);
            var resultFloat = parseFloat(resultString);
        }
        catch(error) {
            resultString = error;
            thisContext.formuleStatus = "formuleNonValide";
        } 

        if(isNaN(resultFloat))
        {
            thisContext.formuleStatus = "formuleNonValide"
        }
        
        return resultFloat;
    }
    

}

export default new CommonService();
