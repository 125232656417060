<!-- ########################## <Template> ########################## -->
<template>
        <!--  -->
        <td>{{libelleIndicateur}}
            <span v-if="isBilanN1NotSelectedAndN1InFormule" style="color:red"><br/>Un seul bilan est sélectionné et N1 est inclus dans la formule</span>
        </td>
        
        <td v-if="validBilansSelection" class="indicateurValueClass" v-tooltip.top="{ value: tooltipValue, escape:true}" >{{formatDecimal2(this.valeurFormule)}}</td>
        <td v-else class="indicateurValueClass" v-tooltip.top="{ value: tooltipValue, escape:true}" >NA</td>
        
        <td  v-if="validBilansSelection">
        <div style="display: flex;align-items: center;">
            {{noteIndicateur}} / {{echelleIndicateur}} 
                &nbsp;<div v-if="historiqueScoreActuelFinancierElement">
                    <span tooltip="La note a changé" style="color:red" v-if="getValidNumberOrReturnZero(historiqueScoreActuelFinancierElement.note) != getValidNumberOrReturnZero(noteIndicateur)">
                        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
                        
                    </span>
                    <span  tooltip="La valeur a changé" style="color:red" v-else-if="formatDecimal2(getValidNumberOrReturnZero(historiqueScoreActuelFinancierElement.valeur)) != formatDecimal2(getValidNumberOrReturnZero(valeurFormule))">
                        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
                        
                    </span>
                    <span tooltip="La formule a changé" style="color:red" v-else-if="historiqueScoreActuelFinancierElement.formule != formule">
                        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
                        
                    </span>
                </div>
            </div>
        </td>
        <td  v-else>
            <div style="display:flex;align-items: center;">
                NA 
                &nbsp;<div v-if="historiqueScoreActuelFinancierElement">
                    <span tooltip="La note a changé" style="color:red" v-if="getValidNumberOrReturnZero(historiqueScoreActuelFinancierElement.note) != getValidNumberOrReturnZero(noteIndicateur)">
                        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
                        
                    </span>
                    <span  tooltip="La valeur a changé" style="color:red" v-else-if="formatDecimal2(getValidNumberOrReturnZero(historiqueScoreActuelFinancierElement.valeur)) != formatDecimal2(getValidNumberOrReturnZero(valeurFormule))">
                        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
                        
                    </span>
                    <span tooltip="La formule a changé" style="color:red" v-else-if="historiqueScoreActuelFinancierElement.formule != formule">
                        <i style="color:orange" class="pi pi-exclamation-triangle"></i>
                        
                    </span>
                </div>
            </div>
        </td>

        <!-- {{historiqueScoreActuelFinancierElement}} -->
        
        <!-- {{ historiqueScoreActuelFinancierElement }} -->
        <!--  -->
</template>
<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import {_} from 'vue-underscore';
import formuleTestData from './FormuleTestData.json';
import CommonService from "../../1-common-components/common.service";

export default {
    name: "ClientScoringIF",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button
    },
    //---------------------------- props ------------------------------
    props: {
        indicateurId: Number,
        libelleIndicateur: String,
        formule: String,
        clientId: Number,
        ordre: Number,
        echelleIndicateur: Number
    },
    //---------------------------- emits ------------------------------
    emits: ["indicateurDeleted"],
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeChoix: [],
            errorMessage : "",
            valeurFormule : 0,
            noteIndicateur : -99,
            formuleAvecValeursBilan: ""
            
        }

    }, //end data()
    computed: {
        
        historiqueScoreActuel()
        {
            return this.$store.state.clientScoringStoreModule.historiqueScoreActuel
        },
        historiqueScoreActuelFinancierElement()
        {
            if(this.historiqueScoreActuel && this.historiqueScoreActuel.jsonFinancier)
            {
                var ref = this;
                return JSON.parse(this.historiqueScoreActuel.jsonFinancier).find(function(d){
                    return d.id == ref.indicateurId
                })
            }
            return null
        },
        tooltipValue()
        {
            return "<div class='myTooltip'><span class='myTooltipHeader'>Formule:</span><br/>"+this.formule+"<br/><span class='myTooltipHeader'>Détail:<br/></span>"+this.formuleAvecValeursBilan+"</div>" ;
        },
        IFsList() {
            return this.$store.state.clientScoringStoreModule.IFsList;
        },
        bilanJson()
        {
            return this.$store.state.clientScoringStoreModule.bilanJson;
        },
        validBilansSelection(){
            return this.$store.state.clientScoringStoreModule.validBilansSelection;
        },
        isBilanN1NotSelectedAndN1InFormule()
        {
            if(this.$store.state.clientScoringStoreModule.bilanJson.N && this.$store.state.clientScoringStoreModule.bilanJson.N1)
            {
                return false;
            }

            if(this.formule.includes('N1.'))
            {
                return true;
            }

            return false
            
        },
        


    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientScoringIF: clientId:" + this.clientId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("----------- mounted()  => ClientScoringIF: clientId:" + this.clientId);
        this.fnLoadIndicateursChoix();
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientScoringIF: clientId:" + this.clientId);
    },

    //-------------------------------- Methods --------------------------
    methods: {
        getValidNumberOrReturnZero(number)
        {
            if(isNaN(number))
            {
                return 0
            }
            else if(!number)
            {
                return 0
            }
            else
            {
                return number
            }
        },
        fnLoadIndicateursChoix()
        {
            console.log("fnLoadIndicateursChoix =>" +this.indicateurId);
            var endpoint = this.isAdminApplication?'indicateursfinancierschoixadmincustom':'indicateursfinancierschoixclientcustom';
            
            CRUDService.get(this.indicateurId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateursChoix reponse");
                this.log(response.data);
                this.listeChoix = response.data;
                this.formuleAvecValeursBilan = this.formule;
                var valeur = CommonService.getResultatFormule(this.formule, this.bilanJson,this);
                console.log("valeur = " +valeur);
                var myVueContext = this;
                var bilanYears = Object.keys(this.bilanJson);
                var bilanAllProperties = [];

                
                for (let ibnp = 0; ibnp < bilanYears.length; ibnp++) {
                    const bilanYearProperty = bilanYears[ibnp];
                    var bilanYearProperties = Object.keys(eval('myVueContext.bilanJson.'+bilanYearProperty));
                    for (let ibyp = 0; ibyp < bilanYearProperties.length; ibyp++) {
                        bilanAllProperties.push(bilanYearProperty+"."+bilanYearProperties[ibyp]);
                    }
                }

                for (let index = 0; index < bilanAllProperties.length; index++) {
                    const propertyName = bilanAllProperties[index];
                    var propValue =  eval('myVueContext.bilanJson.'+propertyName);
                  
                    this.formuleAvecValeursBilan = this.formuleAvecValeursBilan.replaceAll(propertyName,propValue+"");    
                }
                    
                
                var findInterval = {note:-1};
                for (let index = 0; index < this.listeChoix.length; index++) {
                    const element = this.listeChoix[index];

                    var min = element.min;
                    var max = element.max;
                    if(index+1 == this.listeChoix.length) {
                        max = max + Number.EPSILON;
                    }
                    console.log("min="+min+"  max="+max + " note="+element.note+"   valeur="+valeur)
                    if(valeur >= min && valeur < max) {
                        findInterval = element;
                        console.log("note="+element.note)
                        console.log("---------------")
                    }
                }

                var note = findInterval.note;
                var obj = {id:this.indicateurId, valeur: valeur, note:note };
                this.valeurFormule = valeur;
                this.noteIndicateur = obj.note;
                this.$store.commit('clientScoringStoreModule/updateIFsList', obj);
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },
    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.p-tooltip-text{
    min-width: 500px;
    font-size:1.1rem;
}
.myTooltipHeader
{
    color: orange;
}

.IF2Element td{
    border-bottom:1px solid lightgrey;
    height: 50px;
}

.IF2Element .table>:not(caption)>*>* {
    padding: 0.0rem 0.0rem !important;
}

    
</style>