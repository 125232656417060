<!-- ########################## <Template> ########################## -->
<template>
    <!--  -->
    <td style="min-width: 150px;">{{libelleIndicateur}}</td>
    <td style="padding-left:15px;">
        <span v-if="selectedChoice && selectedChoice.id" style="inline-size: 25px; "> 
            <i v-if="!disableEdition" class="pi pi-times" style="cursor:pointer" @click="selectedChoice = false"></i>
            {{selectedChoice.libelleChoix}}
        </span>
        <Dropdown v-else inputStyle="padding:0 !important;width:80px !important; font-size: 0.8rem" v-model="selectedChoice" :options="listeChoix" optionLabel="libelleChoix" @change="handleChange">
        </Dropdown>
        <span style="color:red" v-if="errorNotFound">Le paramétrage qualitatif a été changé et par conséquent est introuvable </span>
        <span tooltip="La note a changé" style="color:red" v-if="selectedChoice && historiqueScoreActuelQualitatifElement && (selectedChoice.note != historiqueScoreActuelQualitatifElement.note && historiqueScoreActuelQualitatifElement.listeChoixId == selectedChoice.id)">
                <i style="color:orange" class="pi pi-exclamation-triangle"></i>
        </span>
    </td>
    <td style="min-width:100px">{{noteIndicateur}} / {{echelleIndicateur}}</td>
    <!--  -->
</template>
<!-- ########################## <Script> ########################## -->
<script>
import kendo from '@progress/kendo-ui'
import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import {_} from 'vue-underscore';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import Dropdown from 'primevue/dropdown';

export default {
    name: "ClientScoringIQ",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
       'kendo-dropdownlist': DropDownList,
       Dropdown
    },
    //---------------------------- props ------------------------------
    props: {
        indicateurId: Number,
        libelleIndicateur: String,
        clientId: Number,
        echelleIndicateur: Number
    },
    //---------------------------- emits ------------------------------
    emits: ["indicateurDeleted"],
    //------------------------------ Data -----------------------------
    data() {
        return {
            listeChoix: [],
            selectedChoice: {id:0, note:0},
            errorMessage : "",
            errorNotFound:false,
            wrappedValue:false
        }

    }, //end data()
    computed: {
        historiqueScoreActuel()
        {
            return this.$store.state.clientScoringStoreModule.historiqueScoreActuel
        },
        disableEdition()
        {
            return this.historiqueScoreActuel.etatValidationQualitative >= 1 || this.historiqueScoreActuel.etatPublication >= 1 || (!this.currentUser.jsonUserRights.scoringQualitatif && !this.currentUser.jsonUserRights.validationQualitative && !this.currentUser.jsonUserRights.scoringPublication)
        },
        historiqueScoreActuelQualitatifElement()
        {
            if(this.historiqueScoreActuel && this.historiqueScoreActuel.jsonQualitatif)
            {
                var ref = this;
                return JSON.parse(this.historiqueScoreActuel.jsonQualitatif).find(function(d){
                    return d.id == ref.indicateurId
                })
            }
            return null
        },
        flagSavedNouveauScore(){
            return this.$store.state.clientScoringStoreModule.flagSavedNouveauScore;
        },
        noteIndicateur()
        {
            // ;
            if(this.selectedChoice && this.selectedChoice.note)
            {
                return this.selectedChoice.note;
            }
            return 0
            
        },
        IQsNotes()
        {
            return this.$store.state.clientScoringStoreModule.IQsNotes;
        }
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientScoringIQ: clientId:" + this.clientId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("----------- mounted()  => ClientScoringIQ: clientId:" + this.clientId);
        this.fnLoadIndicateursChoix();
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientScoringIQ: clientId:" + this.clientId);
    },

    //-------------------------------- Methods --------------------------
    methods: {
        handleChange(e)
        {
            
            var obj = {id:this.selectedChoice.indicateurId, note:this.selectedChoice.note, selected:true ,listeChoixId: this.selectedChoice.id }
            this.$store.commit('clientScoringStoreModule/updateIQsList', obj);
        },
        handleDdlChange(ev)
        {
            this.log("handleDdlChange");
            var ddl = this.$refs.IQDDL.kendoWidget();
            this.selectedChoice = ddl.dataItem();
            var obj = {id:this.selectedChoice.indicateurId, note:this.selectedChoice.note, selected:true ,listeChoixId: this.selectedChoice.id }
            this.$store.commit('clientScoringStoreModule/updateIQsList', obj);
        },
        fnLoadIndicateursChoix()
        {
            var endpoint = this.isAdminApplication?'indicateursqualitatifschoixadmincustom':'indicateursqualitatifschoixclientcustom';
            
            CRUDService.get(this.indicateurId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateursChoix reponse");
                this.log(response.data);
                this.listeChoix = response.data;
                
                //si en mode edit, la liste de choix de l'indicateur qualitatif prend la valeur du json
                if(this.historiqueScoreActuelQualitatifElement)
                {
                    // debugger;
                    this.wrappedValue = true
                    var ref = this;
                    //cherche dans la liste de choix l'element séléctionné
                    var foundItem = this.listeChoix.find(function(d){
                        return d.id == ref.historiqueScoreActuelQualitatifElement.listeChoixId
                    })

                    if(!foundItem && ref.historiqueScoreActuelQualitatifElement.listeChoixId)
                    {
                        this.errorNotFound = true
                    }

                    this.selectedChoice = foundItem

                    var obj = {id:this.selectedChoice.indicateurId, note:this.selectedChoice.note, selected:true ,listeChoixId: this.selectedChoice.id }
                    this.$store.commit('clientScoringStoreModule/updateIQsList', obj);

                    // var ddl = this.$refs.IQDDL.kendoWidget();
                    
                    // ddl.value = foundItem

                    // this.selectedChoice = foundItem

                    //.value("Apples")
                }
                

                // listeChoixId
                
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style >
  
.IQ2Element td{
    border-bottom:1px solid lightgrey;
    height: 50px;
}

.IQ2Element .table>:not(caption)>*>* {
    padding: 0.0rem 0.0rem !important;
   
}



/* .listeChoixDdl
{
    
}
table>:not(caption)>*>* {
    padding: .1rem .1rem !important;
}
table .listeChoixDdl
{
    border-bottom-width: 0px !important;
} */
    
</style>