<!-- ########################## <Template> ########################## -->
<template>
        <!--  --> 
        <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Indicateur</th>
                        <th scope="col">Valeur</th>
                        <th scope="col">Note</th>
                    </tr>
                </thead>
                <tr v-for="indicateur in listeIndicateurs" :key="indicateur.id" class="IF2Element">
                    <ClientScoringIF2Element 
                            :indicateurId="indicateur.id"
                            :libelleIndicateur="indicateur.libelleIndicateur"
                            :formule="indicateur.formule"
                            :clientId="indicateur.clientId"
                            :ordre="indicateur.ordre"    
                            :echelleIndicateur="indicateur.echelleIndicateur"  
                    >
                    </ClientScoringIF2Element>
                </tr>
        </table>
        <!--  -->
         
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientScoringIF2Element from "./ClientScoringIF2Element.vue";
import {_} from 'vue-underscore';
import kendo from '@progress/kendo-ui'


export default {
    name: "ClientScoringIF",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
        ClientScoringIF2Element,
    },
    //---------------------------- props ------------------------------
    props:   ['clientId'],
    //------------------------------ Data -----------------------------
    data() {
        return {
            // listeIndicateurs: [],
            errorMessage : "",
            showFormuleInfo:false
        }

    }, //end data()
    computed: {
        listeIndicateurs()
        {
            return this.$store.state.clientScoringStoreModule.IFsList;
        },
        historiqueScoreActuel()
        {
            return this.$store.state.clientScoringStoreModule.historiqueScoreActuel
        },
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientScoringIF: clientId:" + this.clientId);
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("----------- mounted()  => ClientScoringIF: clientId:" + this.clientId);
        this.fnLoadIndicateurs();
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientScoringIF: clientId:" + this.clientId);
    },

    //-------------------------------- Methods --------------------------
    methods: {
        
        fnLoadIndicateurs()
        {
            // we need to change the webapi endpoint for ClientUser / AdminUser for better security
            var endpoint = this.isAdminApplication?'indicateursfinanciersadmincustom':'indicateursfinanciersclientcustom';
            
            CRUDService.get(this.clientId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateurs reponse");
                this.log(response.data);
                // this.listeIndicateurs = response.data;
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    element.note = 0;
                    this.$store.commit('clientScoringStoreModule/updateIFsList', element);
                }
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.long-list {
  max-height: 12000px;
  overflow: auto;
}
    
</style>